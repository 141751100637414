@font-face{font-family:"icons";src:url("/assets/fonts/icons.eot");src:url("/assets/fonts/icons.eot?#iefix")format("embedded-opentype"),url("/assets/fonts/icons.woff?_=202307131145") format("woff"),url("/assets/fonts/icons.ttf?_=202307131145")format("truetype"),url("/assets/fonts/icons.svg#icons?_=202307131145")format("svg");font-weight:normal;font-style:normal;}
@font-face{font-family:'Droid Serif';font-style:italic;font-weight:400;src:local('Droid Serif Italic'),local('DroidSerif-Italic'),url(/assets/fonts/alt/DROID/DroidSerif-Italic.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:'Droid Serif';font-style:italic;font-weight:700;src:local('Droid Serif Bold Italic'),local('DroidSerif-BoldItalic'),url(/assets/fonts/alt/DROID/DroidSerif-BoldItalic.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:'Droid Serif';font-style:normal;font-weight:400;src:local('Droid Serif Regular'),local('DroidSerif-Regular'),url(/assets/fonts/alt/DROID/DroidSerif-Regular.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:'Droid Serif';font-style:normal;font-weight:700;src:local('Droid Serif Bold'),local('DroidSerif-Bold'),url(/assets/fonts/alt/DROID/DroidSerif-Bold.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_1.woff2?_=202307131145) format('woff2');unicode-range:U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_2.woff2?_=202307131145) format('woff2');unicode-range:U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_3.woff2?_=202307131145) format('woff2');unicode-range:U+1F00-1FFF}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_4.woff2?_=202307131145) format('woff2');unicode-range:U+0370-03FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_5.woff2?_=202307131145) format('woff2');unicode-range:U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_6.woff2?_=202307131145) format('woff2');unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+20A0-20AB,U+20AD-20CF,U+2C60-2C7F,U+A720-A7FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:local('Roboto Light'),local('Roboto-Light'),url(/assets/fonts/alt/ROBOTO/Roboto-Light_7.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_1.woff2?_=202307131145) format('woff2');unicode-range:U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_2.woff2?_=202307131145) format('woff2');unicode-range:U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_3.woff2?_=202307131145) format('woff2');unicode-range:U+1F00-1FFF}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_4.woff2?_=202307131145) format('woff2');unicode-range:U+0370-03FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_5.woff2?_=202307131145) format('woff2');unicode-range:U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_6.woff2?_=202307131145) format('woff2');unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+20A0-20AB,U+20AD-20CF,U+2C60-2C7F,U+A720-A7FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:local('Roboto'),local('Roboto-Regular'),url(/assets/fonts/alt/ROBOTO/Roboto-Regular_7.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_1.woff2?_=202307131145) format('woff2');unicode-range:U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_2.woff2?_=202307131145) format('woff2');unicode-range:U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_3.woff2?_=202307131145) format('woff2');unicode-range:U+1F00-1FFF}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_4.woff2?_=202307131145) format('woff2');unicode-range:U+0370-03FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_5.woff2?_=202307131145) format('woff2');unicode-range:U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_6.woff2?_=202307131145) format('woff2');unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+20A0-20AB,U+20AD-20CF,U+2C60-2C7F,U+A720-A7FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:local('Roboto Medium'),local('Roboto-Medium'),url(/assets/fonts/alt/ROBOTO/Roboto-Medium_7.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_1.woff2?_=202307131145) format('woff2');unicode-range:U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_2.woff2?_=202307131145) format('woff2');unicode-range:U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_3.woff2?_=202307131145) format('woff2');unicode-range:U+1F00-1FFF}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_4.woff2?_=202307131145) format('woff2');unicode-range:U+0370-03FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_5.woff2?_=202307131145) format('woff2');unicode-range:U+0102-0103,U+0110-0111,U+1EA0-1EF9,U+20AB}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_6.woff2?_=202307131145) format('woff2');unicode-range:U+0100-024F,U+0259,U+1E00-1EFF,U+20A0-20AB,U+20AD-20CF,U+2C60-2C7F,U+A720-A7FF}
@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:local('Roboto Bold'),local('Roboto-Bold'),url(/assets/fonts/alt/ROBOTO/Roboto-Bold_7.woff2?_=202307131145) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2212,U+2215}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 300;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 300;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 300;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 300;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 300;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2?_=202307131145) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 400;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2?_=202307131145) format('woff2');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 400;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2?_=202307131145) format('woff2');unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 400;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2?_=202307131145) format('woff2');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 400;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2?_=202307131145) format('woff2');unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 400;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2?_=202307131145) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 700;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 700;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 700;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 700;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2?_=202307131145) format('woff2');unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face {font-family: 'Montserrat';font-style: normal;font-weight: 700;font-display: swap;src: url(/assets/fonts/alt/MONTSERRAT/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2?_=202307131145) format('woff2');unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}

